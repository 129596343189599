/* body {
  background-color: #f7f9fe !important;
}

.welcome_image {
  width: -webkit-fill-available;
}

.isPointer {
  cursor: pointer;
}

.candidateprofile_picture {
  max-width: 100%;
  margin: 0px auto;
}

.tableCustom {
  font-size: 12px;
}

.skillTitleEvaluation {
  font-size: 25px;
  font-weight: lighter;
}

.tableBodyScrollable tbody {
  display: block;
  height: 350px;
  overflow: auto;
}

.tableBodyScrollable thead,
.tableBodyScrollable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableBodyScrollable thead {
  width: calc(100% - 1em);
}

.tableBodyScrollable table {
  width: 400px;
}

.tableBodyScrollable {
  font-size: 12px;
}

.infoLittleText {
  font-size: 13px;
}

.iconSizeSmall {
  font-size: 12px;
}

/* TIMELINE */
.message-item {
  margin-bottom: 25px;
  margin-left: 40px;
  position: relative;
}

.message-item .message-inner {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  position: relative;
}

.message-item .message-inner:before {
  border-right: 10px solid #ddd;
  border-style: solid;
  border-width: 10px;
  color: rgba(0, 0, 0, 0);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: -20px;
  top: 6px;
  width: 0;
}

.message-item .message-inner:after {
  border-right: 10px solid #fff;
  border-style: solid;
  border-width: 10px;
  color: rgba(0, 0, 0, 0);
  content: "";
  display: block;
  height: 0;
  position: absolute;
  left: -18px;
  top: 6px;
  width: 0;
}

.message-item:before {
  background: #fff;
  border-radius: 2px;
  bottom: -30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: -30px;
  position: absolute;
  width: 3px;
}

.message-item:after {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  content: "";
  height: 15px;
  left: -36px;
  position: absolute;
  top: 10px;
  width: 15px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.message-item .message-head {
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.message-item .message-head .avatar {
  margin-right: 20px;
}

.message-item .message-head .user-detail {
  overflow: hidden;
}

.message-item .message-head .user-detail h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.message-item .message-head .post-meta {
  float: left;
  padding: 0 15px 0 0;
}

.message-item .message-head .post-meta>div {
  color: #333;
  font-weight: bold;
  text-align: right;
}

.post-meta>div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}

.message-item .message-head .post-meta>div {
  color: #333;
  font-weight: bold;
  text-align: right;
}

.post-meta>div {
  color: #777;
  font-size: 12px;
  line-height: 22px;
}

/* END TIME LINE**/

.madeUpLabel {
  font-size: 12px;
  color: gray;
}

.dropdown-menu {
  /* left: -102px !important; */
  min-width: 12rem;
}

.dropdown-toggle {
  color: white !important;
  min-width: fit-content importat !important;
}

.drp-contain {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.customTableTD th,
.customTableTD td {
  padding: 1px 0px !important;
  vertical-align: middle !important;
}

.textSmall {
  font-size: 14px;
  padding: 10px;
}

.textSmallLH {
  font-size: 12px;
  line-height: initial;
}

.screen_switch {
  margin-left: 5rem;
}

.my_jobSwitch {
  margin-left: 3rem;
}

.my_talentSwitch {
  margin-left: 1.8rem;
}

.my_assetSwitch {
  margin-left: 2rem;
}

.email_switch {
  margin-left: 1.1rem;
}

/* jim */
/* .form-row {
  margin-bottom: 12px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  /* padding-left: 5px; */
/* display: inline-table;
}

.lineheight {
  line-height: 3;
}

.alignright {
  text-align: right;
  margin-top: 11px;
  margin-right: 30px;
  text-decoration: underline;
}

.dropDownCustomClass {
  font-size: 12px;
}

.textFeedbackDrawer {
  font-size: 12px;
  text-align: justify;
}

.tableEvaluationsTalent {
  font-size: 12px;
}

.textAreaEmailTemplate {
  font-size: 16px !important;
}

.fixStartRating {
  padding-top: -5px;
}

.createNWH {
  background: #f6f6f6;
  padding: 10px !important;
  border-radius: 6px;
  border: 1px solid #dedede;
}

.checklistOfCompletedProfile {
  font-size: 0.9em;
  text-align: left;
  background: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.quoteFeedback {
  background: #f5f5f5;
  border-radius: 5px;
  padding: 4px;
}

.addShadowBox {
  box-shadow: 0px 0px 5px #bdbdbd;
}

.tableAssets th {
  text-align: right;
}

.text-primary-badge,
.text-warning-badge,
.text-danger-badge,
.text-info-badge,
.text-default-badge {
  border-radius: 5px;
  padding: 1px 3px;
}

.text-primary-badge {
  text-align: center;
  color: white;
  background: dodgerblue;
}

.text-warning-badge {
  text-align: center;
  color: white;
  background: #fff330;
}

.text-danger-badge {
  text-align: center;
  color: white;
  background: #e71212;
}

.text-info-badge {
  text-align: center;
  color: white;
  background: #04beae;
}

.text-default-badge {
  border-radius: 5px;
  color: #1d1d1d;
  background: #c1c1c1;
}

.burlywood {
  color: burlywood;
}

.uploadFileColor {
  color: #2aacee;
}

.redIcon {
  color: red !important;
}

.font-size-sm {
  font-size: 12px;
}

.sidebarTalentName {
  font-size: 1em;
  font-weight: bolder;
  text-align: center;
}

.sidebarInfoText9em {
  font-size: 0.9em;
  word-break: break-all;
}

.text9em {
  font-size: 0.8em;
}

.profilePublicWidth {
  max-width: 80rem;
}

.ap_frame_border {
  border-radius: 5px;
}

.my-border {
  padding: 1rem;
  box-shadow: 5px 5px 5px 0.5px lightblue;
  margin: 4rem;
  background-color: rgb(243, 243, 250);
}

.no_appraisal {
  opacity: 0.1;
  height: 20vh;
  width: auto;
}

.noappraisal {
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  position: absolute;
  bottom: 4rem;
  margin-right: 11rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.no_currentappraisal {
  opacity: 0.1;
  height: 65vh;
  width: auto;
}

.nocurrentappraisal {
  width: 16.8rem;
  height: 10rem;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 8rem;
}

.appraisal-finalAccordion {
  padding: 15px;
  border-radius: 25px;
}

.my-icon {
  bottom: 5px;
  position: relative;
  opacity: 0.7;
  padding: 3px;
}

.my-icon-final {
  bottom: 5px;
  position: relative;
  opacity: 0.7;
  padding: 3px;
  margin-left: -12px;
}

/* APPRAISAL CSS */
/* .custom {
  margin-top: 2rem;
  font-size: 13px;
  font-weight: lighter;
}

.img {
  width: 20px;
  height: 20px;
}

.imgs {
  width: 25px;
  height: 25px;
}

.status {
  justify-content: space-between;
}

.appraisee {
  width: 12px;
  height: 12px;
}

#circle {
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 50%;
  text-align: left;
}

.hr {
  width: 10px;
  height: 10px;
}

.img-responsive {
  max-width: 80%;
  max-height: auto;
}

.my-label {
  border: none;
  border-radius: 20px;
  width: 8rem;
  height: 2rem;
  color: black;
  margin-right: 8px;
}

.small_status {
  margin-right: 120px;
}

.sm_status {
  margin-right: 200px;
}

.final_st {
  margin-right: 50px;
}

.badgeCustom {
  height: 20px;
  border: 1px solid #c0c0c4;
  color: white;
  font-size: 12px;
  border-radius: 10px;
  padding: 5px 5px;
}

.customBadge {
  height: 20px;
  border: 1px solid #c0c0c4;
  color: white;
  font-size: 12px;
  border-radius: 10px;
  margin-bottom: 3px;
  padding: 2px 2px;
}

.d-menu {
  z-index: 1000;
}

.target-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em 1rem;
}

a {
  text-decoration: none;
  color: #707075;
}

.target-form-paper {
  max-width: 1100px;
  min-height: 450px;
}

.customBtn {
  width: 130px !important;
  font-size: 11px;
}

.weight input {
  width: 100px;
  margin-left: 22px;
}

.my-drop {
  margin-left: 5.2rem;
}

.ap_eval {
  display: grid;
}

@media screen and (max-width: 720px) {
  .no_currentappraisal {
    opacity: 0.1;
    height: 40vh;
    width: auto;
  }

  .nocurrentappraisal {
    width: 10.8rem;
    height: 10rem;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 3rem;
    z-index: -1;
  }
}

@media screen and (max-width: 465px) {
  .my-border {
    padding: 1rem;
    box-shadow: 5px 5px 5px 0.5px lightblue;
    background-color: rgb(243, 243, 250);
    width: 70%;
  }
} */

/* @media screen and (max-width: 1280px) {
  .ap_per {
    padding: 1rem;
  }

  .ap_btn {
    margin-bottom: 1rem;
  }
}

.share-button {
  position: relative;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ap_txt {
  font-size: 15px;
  color: grey;
}

.share-buttons {
  width: 130px;
  height: 35px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: 0.3s linear;
}

.share-buttons:hover {
  transform: scale(1.1);
}

.share-buttons span {
  position: absolute;
  width: 100%;
  height: 92%;
  background: #1a7fe4;
  color: white;
  text-align: center;
  align-items: center;
  line-height: 34px;
  z-index: 999;
  transition: 0.6s linear;
  border-radius: 4px;
  font-size: 14px;
}

.share-buttons:hover span {
  transform: translateX(-100%);
  transition-delay: 0.3s;
}

.share-buttons a {
  flex: 1;
  font-size: 26px;
  color: #252525.0;
  text-align: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.3s linear;
}

.share-buttons:hover a {
  opacity: 1;
  transform: translateX(0);
}

.share-buttons a:nth-of-type(1) {
  transition-delay: 1s;
}

.share-buttons a:nth-of-type(2) {
  transition-delay: 0.8s;
}

.share-buttons a:nth-of-type(3) {
  transition-delay: 0.6s;
}

.share-buttons a:nth-of-type(4) {
  transition-delay: 0.4s;
}

.ap_dropdown {
  width: 1000%;
}

.statuses0,
.statusesnull,
.statuses1,
.statuses13 {
  background: #1a7fe4;
  font-size: 11px;
  color: white;
}

.my_bt.statuses1,
.my_bt.statuses13 {
  color: white !important;
}

.statuses2,
.statuses4,
.statuses6,
.statuses8,
.statuses14,
.statuses15,
.statuses16,
.statuses17 {
  background: #e5de00;
  color: black;
  font-size: 11px;
}

.statuses10,
.statuses12 {
  background: grey;
  color: black;
  font-size: 11px;
}

.statuses3,
.statuses5,
.statuses7,
.statuses9,
.statuses11 {
  background: rgb(239, 116, 116);
  font-size: 11px;
  color: black;
}

.statuses18 {
  background: #28a745;
  font-size: 11px;
  color: white;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #bcd6f0;
}

::-webkit-scrollbar-thumb {
  border: 9px solid transparent;
  border-radius: 2px;
  background-clip: content-box;
}

.screen_switch {
  margin-right: 5rem;
}

.activeBtn:hover {
  background: #0170ff;
  color: #eff0f9;
  border-color: #0170ff;
}

.sizeBtn {
  width: 120px;
  height: 30px;
  padding: 3px;
}


.asset_switch {
  margin-right: 1rem;
}

.appr_switch {
  margin-right: 2.1rem;
}

.work_switch {
  margin-right: 2.1rem;
}

.jobsCardView-chips {
  background: #e7e6e6;
  color: #646464;
  border-radius: 2px;
  /* border: 1px solid #dedede; */
/* padding: 0 5px;
  word-break: normal;
  margin-right: 5px;
  font-size: 13px;
  width: fit-content;
} */

.jobsCardView-card {
  color: #707075;
}

.jobCardView-detailsSection {
  padding-top: 15px;
  max-height: 82vh;
  overflow-y: scroll;
}

.jobsCardView-btn {
  margin: 5px 0 0 0;
  text-align: end;
}

.JobReadOnlyImg {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.rect {
  background-image: url("/images/Rectangle.webp");
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
  /* top: 0;
  position: sticky;
  z-index: 99; */
}

.text_sm {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.para_sm {
  position: absolute;
  top: 70%;
  left: 20%;
  transform: translateY(-50%);
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.jobs-result {
  top: 60px;
  overflow-y: auto;
}

.job-detail {
  top: 165px;
  height: calc(100vh - 160px);
  position: sticky;
  overflow-y: scroll;
}

.talents-detail {
  top: 5px;
  height: calc(100vh - 30px);
  position: sticky;
  overflow-y: scroll;
}

.welcome-sidebar-filter {
  top: 158px;
  height: calc(100vh - 140px);
  position: sticky;
  overflow-y: scroll;
}

.welcome-sidebar-filter::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.job-detail::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.jobs-result::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.location-list::-webkit-scrollbar {
  width: 0px;
  /* Adjust the width of the scrollbar */
}

.location-list::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Adjust the background color of the scrollbar thumb */
  border-radius: 4px;
  /* Adjust the border radius of the scrollbar thumb */
}

.location-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Adjust the background color of the scrollbar thumb on hover */
}

.location-list::-webkit-scrollbar-track {
  background-color: transparent;
  /* Adjust the background color of the scrollbar track */
  border-radius: 1px;
  /* Adjust the border radius of the scrollbar track */
}

.screening-option {
  background-color: #eff0f9;
}

.screening-option:focus {
  outline: none !important;
}

.react-grid-item.react-grid-placeholder {
  background: transparent !important;
}

.screening-inputbox {
  resize: none;
  overflow-y: hidden;
  border: none;
  background-color: #eff0f9;
  padding: 0.25rem;
}

.dashboard-canvas {
  flex: 1;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
}

.chart-wrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.chart-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-content span {
  cursor: pointer;
}

.chart-content>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-chart {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.chart-content>div>span {
  margin-right: 10px;
}

.chart-content>div>span:last-child {
  margin-right: 100px;
}

.chart-content>div>span:hover {
  color: #aaa;
}

.chart-card {
  width: 100%;
  height: 90%;
}

.chart-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chart-label {
  margin-right: 10px;
}

.chart-filter-select {
  min-width: 100px;
  z-index: 20000;
  margin-right: 100px;
}

.chart-interval-filter {
  width: 50px;
}

.chart-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chart-bar-label {
  margin-right: 10px;
  width: 100px;
}

.jobsCardView-btn {
  margin: 10px 0 0 0;
  text-align: end;
}

.JobReadOnlyImg {
  height: 20px;
  width: 20px;
  margin-right: 15px;
}

.rect {
  background-image: url("/images/Rectangle.webp");
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
  /* top: 0;
  position: sticky; */
  z-index: 99;
}

.text_sm {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.para_sm {
  position: absolute;
  top: 70%;
  left: 20%;
  transform: translateY(-50%);
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.form-second {
  position: sticky;
  top: 4.5rem;
  left: 0;
  height: auto;
  background-color: #0425cc;
  z-index: 100;
  margin: 0 auto;
}

.jobs-result {
  top: 60px;
  overflow-y: auto;
}

.job-detail {
  top: 165px;
  height: calc(100vh - 160px);
  position: sticky;
  overflow-y: scroll;
}

.welcome-sidebar-filter {
  top: 158px;
  height: calc(100vh - 140px);
  position: sticky;
  overflow-y: scroll;
}

.welcome-sidebar-filter::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.job-detail::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.jobs-result::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  /* Make the track transparent */
}

.location-list::-webkit-scrollbar {
  width: 0px;
  /* Adjust the width of the scrollbar */
}

.location-list::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Adjust the background color of the scrollbar thumb */
  border-radius: 4px;
  /* Adjust the border radius of the scrollbar thumb */
}

.location-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Adjust the background color of the scrollbar thumb on hover */
}

.location-list::-webkit-scrollbar-track {
  background-color: transparent;
  /* Adjust the background color of the scrollbar track */
  border-radius: 1px;
  /* Adjust the border radius of the scrollbar track */
}

.screening-option {
  background-color: #eff0f9;
  border: unset;
  padding: 0.35rem;
}

.screening-option:focus {
  outline: none !important;
}

.react-grid-item.react-grid-placeholder {
  background: transparent !important;
}

.screening-inputbox {
  resize: none;
  overflow-y: hidden;
  border: none;
  background-color: #eff0f9;
  padding: 0.25rem;
}

.dashboard-canvas {
  flex: 1;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
}

.chart-wrapper {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.chart-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-content span {
  cursor: pointer;
}

.chart-content>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-chart {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.chart-content>div>span {
  margin-right: 10px;
}

.chart-content>div>span:last-child {
  margin-right: 100px;
}

.chart-content>div>span:hover {
  color: #aaa;
}

.chart-card {
  width: 100%;
  height: 90%;
}

.chart-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chart-label {
  margin-right: 10px;
}

.chart-filter-select {
  min-width: 100px;
  z-index: 20000;
  margin-right: 100px;
}

.chart-interval-filter {
  width: 50px;
}

.chart-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chart-bar-label {
  margin-right: 10px;
  width: 100px;
}

.chart-bar-wrapper {
  flex: 1;
}

.chart-bar-fill {
  height: 20px;
  background-color: #0170ff;
}

.chart-bar-value {
  margin-left: 10px;
}

.select-header-dashboard {
  display: flex;
  align-content: center;
  justify-content: left;
  margin-top: 0.25rem;
  gap: 10px;
}

.dashboardselect {
  display: flex;
  margin-left: 700px;
}

textarea.screening-inputbox:focus {
  border: none;
  outline: none;
}

.questionform-cancel {
  border: none;
  align-items: center;
  width: 100%;
  max-width: 110px;
  text-align: center;
  margin: 0 0.5rem;
}

.questionform-save {
  border: none;
  align-items: center;
  width: 100%;
  max-width: 110px;
  text-align: center;
  margin-right: 0.5rem;
}

.questionform-save:hover {
  background-color: #007bff;
  color: white;
}

.dropdown-item {
  padding: 0.25rem 0.5rem;
}

.chart-filter-container {
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  /* border: 1px solid #C4C4C4; */
  background-color: #ebebeb;
}
.chart-text-empty {
  text-align: center;
  font-size: 18px;
  color: #777;
  padding: 20px;
}

.chart-filter-contain-filtering {
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  /* border: 1px solid #C4C4C4; */
  background-color: #EEEEEE;
}

.filter-dropdown {
  background-color: #ebebeb;
  border: none;
}

.filtering-dropdown {
  background-color: #EEEEEE !important;
  border: none !important;
}

.filter-dropdown option {
  background-color: white;
}

.chart-filter-input {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex: 1;
}

.chart-filter-input-filtering {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex: 1;
}

*/ */ .chart-filter-input label {
  font-size: 0.875rem;
  margin-bottom: 0;
}

*/ */ .chart-filter-input-filtering label {
  font-size: 0.875rem;
  margin-bottom: 0;
}

.chart-filter-input input {
  width: 130px;
  height: 28px;
  border: none;
}

.chart-filter-input-filtering input {
  width: 140px;
  height: 28px;
  padding: 0 5px;
  border: 1px solid gray;
  border-radius: 5px;
}

.chart-container {
  height: 100%;
}

.custom-dropdown {
  position: static;
  display: inline-block;
  min-width: 240px;
  width: fit-content;
}

.selected-option {
  cursor: pointer;
  padding: 8px;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-left: 10px;
  padding-top: 10px;
}

.dropdown-list {
  position: absolute;
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #ccc;
}

.dropdown-option {
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  margin-right: 30px;
}

.dropdown-option:hover {
  background-color: #1ba1e2;
  color: white;
}

.dropdown-option.selected {
  background-color: #f1f1f1;
}

.delete-option {
  /* margin-left: 100px; */
  position: absolute;
  right: 10px;
  border: none;
  background: none;
  /* color: red; */
  cursor: pointer;
}

.dashboard-category {
  height: 50px;
  border: none;
  padding: 10px;
  background: #ebebeb;
}

.dashboard-category option {
  background-color: white;
}

.react-datepicker-calendar-icon {
  display: block;
}

.dashboard-report {
  background: #ebebeb;
  height: 50px;
  padding: 10px;
}

.customize-dropdown {
  position: absolute;
  right: 10px;
}

.filter-custom-input {
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none"><path d="M0.025 0V5H17.525V0H0.025ZM0.025 7.5V19.775C0.025 19.9 0.125 20 0.25 20H17.275C17.4 20 17.5 19.9 17.5 19.775V7.5H0H0.025ZM2.525 10H5.025V12.5H2.525V10ZM7.525 10H10.025V12.5H7.525V10ZM12.525 10H15.025V12.5H12.525V10ZM2.525 15H5.025V17.5H2.525V15ZM7.525 15H10.025V17.5H7.525V15Z" fill="gray"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 32px;
  width: 240px;
  height: 24px;
  display: flex;
  align-items: center;
}

.filter-custom-input input {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  outline: none;
}

.filter-custom-input:focus-visible {
  border-bottom-color: #0425cc;
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0425cc;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white;
  border: 1px solid #707075#b51148;
  margin: 0.066rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #0425cc;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.filter-datepicker-class .react-datepicker__today-button {
  color: #007bff;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.custom-container {
  margin: 20px;
}

.custom-title {
  padding: 3px;
  font-size: 20px;
}

.custom-icon {
  float: right;
  position: sticky;
  margin: 10px;
  margin-left: 45rem;
  color: rgb(10, 160, 241);
  cursor: pointer;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.custom-table th {
  background-color: #dfe1e0;
  padding: 5px;
}

.custom-table td {
  padding: 5px;
  /* border-bottom: 1px solid white; */
}

.custom-icon-container {
  display: flex;
  justify-content: center;
}

.custom-icon-container svg {
  margin: 0 5px;
  color: gray;
  cursor: pointer;
}

.custom-popover-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

/* Add more custom styles as needed */

.chip-content {
  margin: 5px;
  padding: 5px;
}

.nvl {
  margin-left: 1rem;
  position: sticky;
}

.nvl:hover {
  width: 250px;
  background-color: #e0ecf9;
  border-radius: 5px;
  color: gray;
}

.scrollable-element {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  /* Hide the scrollbar */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer & Edge */
}

.scrollable-element::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.custom-link {
  text-decoration: none;
}

/* Add more custom styles as needed */

.chip-content {
  margin: 5px;
  padding: 5px;
}

.nvl {
  margin-left: 1rem;
  position: sticky;
}

.nvl:hover {
  width: 250px;
  background-color: #e0ecf9;
  border-radius: 5px;
  color: gray;
}

.scrollable-element {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  /* Hide the scrollbar */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer & Edge */
}

.scrollable-element::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.custom-link {
  text-decoration: none;
}

.custom-link:hover {
  text-decoration: none;
}

.jobChangeStatus-dropdown {
  min-width: 8rem !important;
}

.nav-item {
  cursor: pointer;
}

.custom-table-dashboard {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-dashboard th,
.custom-table-dashboard td {
  /* border: 1px solid #ccc; */
  padding: 8px;
  text-align: left;
}

/* Set specific widths for each column */
.custom-table-dashboard th:nth-child(1),
.custom-table-dashboard td:nth-child(1) {
  width: 15%;
}

.custom-table-dashboard th:nth-child(2),
.custom-table-dashboard td:nth-child(2) {
  width: 15%;
}

.custom-table-dashboard th:nth-child(3),
.custom-table-dashboard td:nth-child(3) {
  width: 15%;
}

.custom-table-dashboard th:nth-child(4),
.custom-table-dashboard td:nth-child(4) {
  text-align: center;
  width: 8%;
}

.custom-table-dashboard th:nth-child(5),
.custom-table-dashboard td:nth-child(5) {
  width: 10%;
}

.custom-table-dashboard th:nth-child(6),
.custom-table-dashboard td:nth-child(6) {
  /* text-align: center; */
  width: 15%;
}

.custom-table-dashboard th:nth-child(7),
.custom-table-dashboard td:nth-child(7) {
  text-align: center;
  width: 15%;
}

/* .custom-table-dashboard th:nth-child(7),
.custom-table-dashboard td:nth-child(7) {
  width: 10%;
} */

.recruiter-filter {
  display: flex;
}

.recruiter-filter .dashboard-select {
  min-width: 250px;
}

.custom-table-dashboard-recruiter {
  width: 100%;
}

.custom-table-dashboard-recruiter th,
.custom-table-dashboard-recruiter td {
  /* border: 1px solid #ccc; */
  padding: 4px;
  text-align: left;
}

.custom-table-dashboard-recruiter th:nth-child(1),
.custom-table-dashboard-recruiter td:nth-child(1) {
  width: 10%;
}

.custom-table-dashboard-recruiter th:nth-child(2),
.custom-table-dashboard-recruiter td:nth-child(2) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(3),
.custom-table-dashboard-recruiter td:nth-child(3) {
  width: 10%;
  text-align: center;
}

.custom-table-dashboard-recruiter th:nth-child(4),
.custom-table-dashboard-recruiter td:nth-child(4) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(5),
.custom-table-dashboard-recruiter td:nth-child(5) {
  width: 10%;
}

.custom-table-dashboard-recruiter th:nth-child(6),
.custom-table-dashboard-recruiter td:nth-child(6) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(7),
.custom-table-dashboard-recruiter td:nth-child(7) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(8),
.custom-table-dashboard-recruiter td:nth-child(8) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(9),
.custom-table-dashboard-recruiter td:nth-child(9) {
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(10),
.custom-table-dashboard-recruiter td:nth-child(10) {
  /* text-align: center; */
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(11),
.custom-table-dashboard-recruiter td:nth-child(11) {
  text-align: center;
  width: 15%;
}

.custom-table-dashboard-recruiter th:nth-child(12),
.custom-table-dashboard-recruiter td:nth-child(12) {
  /* text-align: center; */
  width: 15%;
}

.days-tab {
  height: 92%;
}

.recruiter-report-body {
  font-size: small;
}

.recruiter-report-rows {
  /* border-bottom: gray; */
  box-shadow: 0 0.5px 0 #ccc;
}

.emailUseList li {
  margin-top: 1rem;
}

.emailUseList li:hover {
  cursor: pointer;
  background: #deeafa;
  border-radius: 10px;
  padding: 5px;
}

.recruiter-numeric-rows {
  align-items: center;
  align-content: center;
  text-align: center;
}

.recruiter-funnel {
  display: flex;
  flex-direction: row;
}

.recruiter-report-table {
  width: 75%;
}

.funnel-report {
  margin-right: 10px;
  width: 30%;
}

.funnel-chart {
  width: 300px;
}

.funnel-total {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.recruiter-report-table th,
.recruiter-report-table td {
  /* border: 1px solid #ccc; */
  padding: 4px;
  text-align: left;
  text-wrap: wrap;
}

.recruiter-report-table th:nth-child(1),
.recruiter-report-table td:nth-child(1) {
  width: 7%;
}

.recruiter-report-table th:nth-child(2),
.recruiter-report-table td:nth-child(2) {
  width: 5%;
}

.recruiter-report-table th:nth-child(3),
.recruiter-report-table td:nth-child(3) {
  width: 5%;
  text-align: center;
}

.recruiter-report-table th:nth-child(4),
.recruiter-report-table td:nth-child(4) {
  width: 5%;
}

.recruiter-report-table th:nth-child(5),
.recruiter-report-table td:nth-child(5) {
  width: 7%;
}

.recruiter-report-table th:nth-child(6),
.recruiter-report-table td:nth-child(6) {
  width: 5%;
}

.recruiter-report-table th:nth-child(7),
.recruiter-report-table td:nth-child(7) {
  text-align: center;

  width: 5%;
}

.recruiter-report-table th:nth-child(8),
.recruiter-report-table td:nth-child(8) {
  text-align: center;

  width: 5%;
}

.recruiter-report-table th:nth-child(9),
.recruiter-report-table td:nth-child(9) {
  text-align: center;

  width: 5%;
}

.recruiter-report-table th:nth-child(10),
.recruiter-report-table td:nth-child(10) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(11),
.recruiter-report-table td:nth-child(11) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(12),
.recruiter-report-table td:nth-child(12) {
  text-align: center;
  width: 7%;
}

.recruiter-report-table th:nth-child(13),
.recruiter-report-table td:nth-child(13) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(14),
.recruiter-report-table td:nth-child(14) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(15),
.recruiter-report-table td:nth-child(15) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(16),
.recruiter-report-table td:nth-child(16) {
  text-align: center;
  width: 5%;
}

.recruiter-report-table th:nth-child(17),
.recruiter-report-table td:nth-child(17) {
  text-align: center;
  width: 5%;
}

.workload-resolver-table {
  width: 100%;
}

.workload-resolver-table th,
.workload-resolver-table td {
  /* border: 1px solid #ccc; */
  padding: 4px;
  text-align: left;
}

.workload-resolver-table th:nth-child(1),
.workload-resolver-table td:nth-child(1) {
  width: 15%;
}

.workload-resolver-table th:nth-child(2),
.workload-resolver-table td:nth-child(2) {
  width: 10%;
  text-align: center;
}

.workload-resolver-table th:nth-child(3),
.workload-resolver-table td:nth-child(3) {
  width: 10%;
  text-align: center;
}

.workload-resolver-table th:nth-child(4),
.workload-resolver-table td:nth-child(4) {
  width: 10%;
  text-align: center;
}

.workload-resolver-table th:nth-child(5),
.workload-resolver-table td:nth-child(5) {
  width: 20%;
  text-align: center;
}

.workload-resolver-table th:nth-child(6),
.workload-resolver-table td:nth-child(6) {
  text-align: center;

  width: 20%;
}

.job-position-table {
  width: 100%;
}

.job-position-table th,
.job-position-table td {
  /* border: 1px solid #ccc; */
  padding: 4px;
  text-align: left;
}

.job-position-table th:nth-child(1),
.job-position-table td:nth-child(1) {
  width: 50%;
}

.job-position-table th:nth-child(2),
.job-position-table td:nth-child(2) {
  width: 20%;
  text-align: center;
}

.sales-performance {
  width: 100%;
}

.sales-performance th,
.sales-performance td {
  /* border: 1px solid #ccc; */
  padding: 4px;
  text-align: left;
}

.sales-performance th:nth-child(1),
.sales-performance td:nth-child(1) {
  width: 10%;
}

.sales-performance th:nth-child(2),
.sales-performance td:nth-child(2) {
  width: 15%;
}

.sales-performance th:nth-child(3),
.sales-performance td:nth-child(3) {
  width: 15%;
}

.sales-performance th:nth-child(4),
.sales-performance td:nth-child(4) {
  width: 10%;
}

.sales-performance th:nth-child(5),
.sales-performance td:nth-child(5) {
  text-align: center;
  width: 7%;
}

.sales-performance th:nth-child(6),
.sales-performance td:nth-child(6) {
  text-align: center;
  width: 7%;
}

.sales-performance th:nth-child(7),
.sales-performance td:nth-child(7) {
  text-align: center;

  width: 7%;
}

.sales-performance th:nth-child(8),
.sales-performance td:nth-child(8) {
  text-align: center;

  width: 8%;
}

.sales-performance th:nth-child(9),
.sales-performance td:nth-child(9) {
  text-align: center;

  width: 7%;
}

.sales-performance th:nth-child(10),
.sales-performance td:nth-child(10) {
  text-align: center;
  width: 7%;
}

.sales-performance th:nth-child(11),
.sales-performance td:nth-child(11) {
  text-align: center;
  width: 7%;
}

.sales-performance th:nth-child(12),
.sales-performance td:nth-child(12) {
  text-align: center;
  width: 7%;
}

.export-onboarded {
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 20px;
}

.drag-drop {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.locationComponent-dropdown {
  position: absolute;
  background-color: rgb(230, 230, 230);
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  max-height: 14rem;
  overflow-y: auto;
  z-index: 200;
  width: 97%;
}

.location-option {
  border-bottom: 1px solid white;
  padding: 0 3px;
}

.location-option:hover {
  background-color: #f0f0f0;
}

.file-upload-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload-container {
  margin-left: 10px;
  /* Adjust the margin as needed */
}

.sq-addButton {
  display: block;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.parseBtns {
  display: flex;
  justify-content: space-between;
}

.parsing-header {
  display: block;
  position: sticky;
  top: 50px;
  z-index: 9999999;
  background-color: white;
}

.personal-info {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 2rem;
  width: 60%;
}

.candidate-index {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  padding: 0.5rem;
}

.plus-icon {
  color: #007bff;
  cursor: pointer;
}

.parsefeature {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.skill-array {
  display: flex;
  /* justify-content: space-between; */
  /* width: 80%; */
  padding: 1rem;
  background-color: #EFEEEE;
  flex-wrap: wrap;
  gap: 1rem;
}

.skill-value {
  padding: 3px 1rem;
  background-color: white;
  /* width: 30%; */
  display: flex;
  justify-content: space-between;
}

.job-company {
  display: flex;
  justify-content: space-between;
}

.resume-cross {
  cursor: pointer;
  padding-left: 20px;
}

.work-history {
  padding: 2rem 0 0 0;
}

.upload-file {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.column-save {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.work-history-item {
  /* padding: 1rem 0 0 0; */
  border: 1px solid #ccc;
}

.candidate-info-card {
  width: 100%;
}

.candidateremove {
  display: flex;
  justify-content: flex-end;
}

.aiJd_button {
  position: absolute;
  right: 26px;
  bottom: 13px;
  border: none;
  z-index: 100;
}

.jobTitleSearch-ul {
  position: absolute;
  max-height: 270px;
  overflow-y: auto;
  border: 1px solid #c4c4c4;
  border-radius: 0.3rem;
  z-index: 10;
  background-color: #f0f0f0;
  width: 33.7rem;
  padding: 0 auto;
  top: 5.5rem;
  left: 1.2rem;
}

.jobTitleSearch-ul::-webkit-scrollbar {
  width: 2px;
}

.jobTitleSearch-ul li {
  padding: 0.25rem 0.5rem;
  background-color: white;
  margin: 5px;
  cursor: pointer;
}

.actionBtnCandidateSelector {
  display: grid;
  grid-template-columns: auto auto auto;
}

.cancel-icon {
  color: #007bff;
  background-color: transparent;
  border: none;
}

.work-history-header {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.save-work-history {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.workload_closed_per_day {
  /* width: 10vw; */
  background-color: #DFE1E0
}

.workload_closed_per_day th {
  /* background-color: #DFE1E0 */
  padding: 10px 30px;
}

.workload_closed_body td {
  padding: 10px 30px;

}

.workload_closed_table {
  width: 100%;
}

.ticket_filter {
  position: absolute;
  top: -20px;
  right: 0;
}

.category_logo {
  width: 35px !important;
  height: 35px !important;
  opacity: 0.6;

}

.custom_date_workload {
  /* background-color: #E4F0FF; */
  padding: 30px 20px;
  margin: 1.2rem 0;
  border: 1px solid lightgray;
  position: relative;
}

.workload_closed_main {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.workload_closed_body {
  display: flex;
  gap: 2rem;
  font-size: x-large;
}

.workload_closed_select {
  display: flex;
  gap: 1rem;

}

.closed_select {
  /* background-color: #E4F0FF; */
}

.wl-closed-select {
  display: flex;
  flex-direction: column;
}

.closed-select-all {
  font-size: 15px;
  /* justify-items: end; */
  align-self: end;
  color: #0170FF;
}

.closed_heading {
  font-size: medium;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
}

.wl_closed_empty {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;

}

.wl_closed_report {
  color: #0165E6;
  font-size: 20px;
}

.wl_closed_report_text {

  font-size: 20px;
}

.employee-skill-dashboard {
  width: 100%;
  border-collapse: collapse;
}

.employee-skill-dashboard th,
.employee-skill-dashboard td {
  /* border: 1px solid #ccc; */
  padding: 8px;
  text-align: left;
}

/* Set specific widths for each column */
.employee-skill-dashboard th:nth-child(1),
.employee-skill-dashboard td:nth-child(1) {
  width: 8%;
}

.employee-skill-dashboard th:nth-child(2),
.employee-skill-dashboard td:nth-child(2) {
  width: 8%;
}

.employee-skill-dashboard th:nth-child(3),
.employee-skill-dashboard td:nth-child(3) {
  width: 5%;
  text-align: center;
}

.employee-skill-dashboard th:nth-child(4),
.employee-skill-dashboard td:nth-child(4) {
  text-align: center;
  width: 8%;
}

.employee-skill-dashboard th:nth-child(5),
.employee-skill-dashboard td:nth-child(5) {
  width: 8%;
}

.employee-skill-dashboard th:nth-child(6),
.employee-skill-dashboard td:nth-child(6) {
  /* text-align: center; */
  width: 8%;
}

.employee-skill-dashboard th:nth-child(7),
.employee-skill-dashboard td:nth-child(7) {
  /* text-align: center; */
  width: 15%;
}

.employee-skill-dashboard th:nth-child(8),
.employee-skill-dashboard td:nth-child(8) {
  width: 10%;
}

.employee-skill-dashboard th:nth-child(9),
.employee-skill-dashboard td:nth-child(9) {
  width: 10%;
}

.employee-skill-dashboard th:nth-child(10),
.employee-skill-dashboard td:nth-child(10) {
  width: 10%;
}

.employee-skill-dashboard th:nth-child(11),
.employee-skill-dashboard td:nth-child(11) {
  width: 10%;
}

/* Truncate text in cells */
.employee-skill-dashboard td .truncate {
  display: inline-block;
  /* Required for ellipsis */
  max-width: 150px;
  /* Adjust to fit your table */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.paper-container {
  height: 75vh;
  max-width: 500px;
  margin: auto;
  padding: 2rem;
  border-radius: 15px;
  background-color: #f9fafc;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.email-image {
  width: 120px;
  border-radius: 10%;
  border: 1px solid #007bff;
}

.verify-header {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.verify-description {
  font-size: 1rem;
  color: #555;
  margin: 1rem 0;
}

.verify-footer {
  font-size: 0.875rem;
  color: #777;
  margin-top: 1rem;
}
.loading-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9fafc;
  color: #333;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.loading-spinner {
  color: #007bff;
  margin-bottom: 1.5rem;
}

.loading-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 1rem;
}

.loading-description {
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
  max-width: 500px;
  line-height: 1.5;
}

.terms-container {
  max-width: 800px;
  margin: 30px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.terms-main-title {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
}

.terms-intro {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.terms-section {
  margin-bottom: 20px;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.error {
  color: red;
  font-size: 0.875rem; /* Slightly smaller for subtlety */
  margin-top: 2px;
}


.terms-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #34495e;
  border-bottom: 2px solid #3498db;
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.terms-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.terms-list {
  padding-left: 20px;
  list-style-type: disc;
}

.terms-list li {
  margin-bottom: 8px;
  color: #444;
}

.terms-agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
}

.terms-checkbox {
  width: 18px;
  height: 12px;
  cursor: pointer;
  margin-right: 10px;
  accent-color: #007bff;
}
.circular-prog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.terms-label {
  font-size: 1rem;
  color: #2c3e50;
  cursor: pointer;
}
.elite-agreement-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5rem 20rem;
  gap: 2rem;
  font-size: 1.1rem;
}
.elite-agreement-container div{
  text-align: justify;
}
.elite-main-heading{
  text-align:center;
  font-weight: bold;
}
.elite-bold{
  font-weight: bold;
}
.elite-subheading{
  display: flex;
  gap: 1rem;
}
.elite-list{
  margin-left: 2rem;
}
.elite-subheading ul {
  list-style-type: disc; /* Ensures bullet points appear */
  margin-left: 20px; /* Indents the list a bit */
  padding-left: 0;
}

.elite-subheading li {
  margin-bottom: 5px; /* Adds a little space between items */
}
.elite-center-heading {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.elite-schedule p {
  font-size: 16px;
  margin-bottom: 10px;
}

.elite-schedule ul {
  list-style-type: disc;
  padding-left: 20px;
}

.elite-schedule li {
  margin-bottom: 10px;
  line-height: 1.6;
}
.elite-link{
  color: #0165E6;
  margin-left: 10px;
}
.elite-and{
  font-weight: bold;
  font-size: large;
  /* justify-content: center; */
  text-align: center;
}
@media (max-width: 768px) {
  .terms-container {
      padding: 15px;
  }
  .terms-title {
      font-size: 1.2rem;
  }
  
}
